import React from "react";

import SEO from "../components/seo";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
import MyLink from "../components/MyLink";

const IndexPage = () => {
  return (
    <main
      className="bg-gray-100 h-screen flex flex-col"
      style={{ fontFamily: "-apple-system, Roboto, sans-serif, serif" }}
    >
      <SEO title="One Step" includeMainTitle={false} />
      <NavBar />
      <main className="mt-16 sm:mt-32 pb-10 md:py-0 mx-auto max-w-7xl px-4 grow">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">All it takes is</span>{" "}
            <span className="block text-blue-600 xl:inline">One Step</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Bring your ideas to life. Go from concept, to using your app in no
            time.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <MyLink
                href="mailto:contact@one-step.io"
                eventName="main_contact_clicked"
                linkType="event_link"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
              >
                Contact
              </MyLink>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <MyLink
                href="/projects"
                linkType="page_link"
                eventName="main_view_projects_clicked"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
              >
                View Projects
              </MyLink>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </main>
  );
};

export default IndexPage;
